const {
  location: { host },
} = window;
// DEFINE
const dev = {
  server: 'https://dev.bilagsky.no',
  web: 'https://d1k2osl84ll4ia.cloudfront.net',
  tokenSecret: 'bilagsky',
  socket: 'https://socket-dev.bilagsky.no',
  bankEndPoint: 'https://dev-bankservices.bilagsky.no',
  orytonEndPoint: 'https://api.oryton.vn',
  ACCOUNT_WEB: 'https://account.oryton.vn',
  ACCOUNT_API: 'https://api-account.oryton.vn',
  ORYTON_WEB: 'https://oryton.vn',
  ehfEndpoint: 'https://dev-ehf-service.bilagsky.no',
  rgbEndpoint: 'https://dev-rgb.oryton.no',
  signantEndpoint: 'https://dev-rgb.oryton.no',
  taskEndpoint: 'https://dev-tasks.bilagsky.no',
  trackingBankEndpoint: 'https://dev-tracking-bank.bilagsky.no',
  statisticsEndpoint: 'https://dev-statistics.bilagsky.no',
  mailServerEndpoint: 'https://dev-mailserver.bilagsky.no',
  ztlEndpoint: 'https://dev-ztl.bilagsky.no',
  voucherAIEndpoint: 'https://dev-ai-voucher.bilagsky.no',
};
const pro = {
  server: 'https://ajax-server.bilagsky.no', // staging: https://ajax-server.bilagsky.no
  web: 'https://dnk9q12ozeji7.cloudfront.net', // https://bilagsky.no
  tokenSecret: 'bilagsky',
  socket: 'https://socket.bilagsky.no',
  bankEndPoint: 'https://bankservices.bilagsky.no',
  orytonEndPoint: 'https://api.oryton.no',
  ACCOUNT_WEB: 'https://account.oryton.no',
  ACCOUNT_API: 'https://api-account.oryton.no',
  ORYTON_WEB: 'https://oryton.no',
  ehfEndpoint: 'https://ehf-service.bilagsky.no',
  rgbEndpoint: 'https://rgb.oryton.no',
  signantEndpoint: 'https://rgb.oryton.no',
  taskEndpoint: 'https://tasks.bilagsky.no',
  trackingBankEndpoint: 'https://tracking-bank.bilagsky.no',
  statisticsEndpoint: 'https://statistics.bilagsky.no',
  mailServerEndpoint: 'https://mailserver.bilagsky.no',
  ztlEndpoint: 'https://ztl.bilagsky.no',
  voucherAIEndpoint: 'https://ai-voucher.bilagsky.no',
};
/* END DEFINE */

// LOCAL
export const development = {
  server: 'https://dev.bilagsky.no',
  web: 'http://localhost:4002',
  tokenSecret: 'bilagsky',
  socket: 'https://socket.bilagsky.no',
  bankEndPoint: 'https://bankservices.bilagsky.no',
  orytonEndPoint: 'https://api.oryton.no',
  ACCOUNT_WEB: 'https://account.oryton.no',
  ACCOUNT_API: 'https://api-account.oryton.no',
  ORYTON_WEB: 'https://oryton.no',
  ehfEndpoint: 'https://dev-ehf-service.bilagsky.no',
  rgbEndpoint: 'https://dev-rgb.oryton.no',
  signantEndpoint: 'https://dev-rgb.oryton.no',
  taskEndpoint: 'https://dev-tasks.bilagsky.no',
  trackingBankEndpoint: 'https://dev-tracking-bank.bilagsky.no',
  statisticsEndpoint: 'https://dev-statistics.bilagsky.no',
  mailServerEndpoint: 'https://dev-mailserver.bilagsky.no',
  ztlEndpoint: 'https://dev-ztl.bilagsky.no',
  voucherAIEndpoint: 'https://dev-ai-voucher.bilagsky.no',
};
// PRODUCTION
export const production = host.search(/dev.bilagsky.no/g) !== -1 ? dev : pro;
